<template>
  <!-- 车牌键盘 -->
  <div id="dispense" navbar-fixed>
    <!-- 输入框 -->
    <div class="content-car-number">
      <div class="car-content-title" v-if="xnyCheck">
        <span>车牌号码</span>
        <label class="ze-checkbox">
          <input type="checkbox" v-model="checkbox" @change="carTypeChange" />
          <span class="ze-checkbox-icon checkborder">
            <i></i>
          </span>
          <span class="ze-checkbox-text">新能源</span>
        </label>
      </div>
      <div class="content-section">
        <div class="content-section-flex flex-card-border">
          <button id="font" class="flex-btn" @click="btnClickYue" :class="{isClick: isYue}" type="button" v-if="1 - carNumHide > 0">
            {{ areaName }}
          </button>
          <button id="letter" class="flex-btn" @click="btnClickA" :class="{isClick: isA}" type="button" v-if="2 - carNumHide > 0">
            {{ areaLetter }}
          </button>
          <button id="numOne" @click="btnClickNum('one')" :class="{isNumClick: isNumOne}" type="button" v-if="3 - carNumHide > 0">
            {{ numOne }}
          </button>
          <button id="numTwo" @click="btnClickNum('two')" :class="{isNumClick: isNumTwo}" type="button" v-if="4 - carNumHide > 0">
            {{ numTwo }}
          </button>
          <button id="numThree" @click="btnClickNum('three')" :class="{isNumClick: isNumThree}" type="button" v-if="5 - carNumHide > 0">
            {{ numThree }}
          </button>
          <button id="numFour" @click="btnClickNum('four')" :class="{isNumClick: isNumFour}" type="button" v-if="6 - carNumHide > 0">
            {{ numFour }}
          </button>
          <button id="numFive" @click="btnClickNum('five')" :class="{isNumClick: isNumFive}" type="button" v-if="7 - carNumHide > 0">
            {{ numFive }}
          </button>
          <button v-if="checkbox" id="numSix" @click="btnClickNum('six')" type="button" :class="{isNumClick: isNumSix}">
            {{ numSix }}
          </button>
        </div>
      </div>
      <!-- <div class="history-complete" v-if="historyShow" @click="historycar_show = true">
        历史记录
      </div> -->
    </div>
    <!-- 键盘 -->
    <div class="keyboard" v-show="keyboardShow" :class="{animationDown: isDown, animationUp: isUp}">
      <div class="complete-box">
        <button class="btn-complete" @click="completeClick" type="button">完成</button>
      </div>
      <div v-if="keyboard == 'txt'">
        <div class="keyboard-row" v-for="(item, rows) in carTxt">
          <button class="keyboard-row-item" v-for="(i, index) in item.name" @click="btnWordClick(rows, index, i)" type="button">
            {{ i }}
          </button>
        </div>
        <div class="keyboard-row">
          <!-- <div class="keyboard-row-items"> -->
          <button class="keyboard-row-item bottom" @click="btnBottomClick('新')" type="button">新</button>
          <button class="keyboard-row-item bottom" @click="btnBottomClick('临')" type="button">临</button>
          <button v-for="j in noneBottomtxt" class="none-botton" type="button">
            {{ j }}
          </button>
          <div class="keyboard-row-item clear" @click="clearClick">
            <img src="@/assets/images/car/icon_input_delete.png" alt="删除" />
          </div>
          <!-- </div> -->
        </div>
      </div>
      <div v-if="keyboard == 'num'">
        <div class="keyboard-row" v-for="(item, rows) in carNum">
          <button
            :disabled="!isSelectl ? isDisable && i < 10 : isDisable && rows != 0"
            class="keyboard-row-item"
            v-for="(i, index) in item.name"
            @click="btnWordClick(rows, index, i)"
            type="button"
          >
            {{ i }}
          </button>
        </div>
        <div class="keyboard-row">
          <!-- <div class="keyboard-row-bottom"> -->
          <button
            :disabled="item == '港' || item == '澳' || item == '学' ? !istextDisable : false"
            v-for="item in carNumBottom"
            class="keyboard-row-item bottom"
            type="button"
            @click="btnBottomNumClick(item)"
          >
            {{ item }}
          </button>
          <button v-for="j in noneBottom" class="none-botton" type="button">
            {{ j }}
          </button>
          <div class="keyboard-row-item clear" @click="clearClick">
            <img src="@/assets/images/car/icon_input_delete.png" alt="删除" />
          </div>
        </div>
      </div>
    </div>
    <!-- 历史记录弹窗 -->
    <van-dialog v-model="historycar_show" title="历史记录" confirmButtonText="关闭">
      <van-cell-group class="history-box" :border="false">
        <van-cell center v-for="(item, index) of history_car" :key="index" @click="chooseHistoryCar(item)">
          <template #title>
            <div>
              {{ item.number }} <span v-if="item.name !== ''">({{ item.name }})</span>
            </div>
          </template>
          <template #right-icon>
            <van-icon name="delete-o" size="20" @click.stop="deleteHistoryCar(item)" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {getHistorycarList, setHistorycarList} from '@/utils/auth'
import {Dialog} from 'vant'

export default {
  props: {
    carNumAll: {
      type: String,
      default: '',
    },
    xnyCheck: {
      // 是否显示新能源勾选
      type: Boolean,
      default: true,
    },
    firstCheckbox: {
      // 新能源初始勾选状态
      type: Boolean,
      default: true,
    },
    carNumHide: {
      // 影藏前面N位输入框
      type: Number,
      default: 0,
    },
    historyShow: {
      // 是否显示历史记录
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isFeeHourClick: false,
      checkbox: true,
      isDown: false,
      isUp: false,
      selected: null,
      isSelectx: false,
      isSelectl: false,
      key: 1,
      areaName: '',
      areaLetter: '',
      numOne: '',
      numTwo: '',
      numThree: '',
      numFour: '',
      numFive: '',
      numSix: '',
      isYue: false,
      keyboardShow: false,
      keyboard: false,
      isA: false,
      isNumOne: false,
      isNumTwo: false,
      isNumThree: false,
      isNumFour: false,
      isNumFive: false,
      isNumSix: false,
      isDisable: false,
      istextDisable: false,
      isOne: false,
      isTwo: false,
      isThree: false,
      isFour: false,
      isFive: false,
      isSix: false,
      isSeven: false,
      isEight: false,
      isOther: false,
      carTxt: [
        {name: ['粤', '京', '冀', '沪', '津', '晋', '蒙', '辽', '吉', '黑']},
        {name: ['苏', '浙', '皖', '闽', '赣', '鲁', '豫', '鄂', '湘', '桂']},
        {name: ['琼', '渝', '川', '贵', '云', '藏', '陕', '甘', '青', '宁']},
      ],
      carNumBottom: ['W', 'X', 'Y', 'Z'],
      noneBottom: ['', '', '', ''],
      noneBottomtxt: ['', '', '', '', '', ''],
      carNum: [
        {name: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']},
        {name: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K']},
        {name: ['L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V']},
      ],
      addedCarNum: '',
      usecar_list: [],
      historycar_show: false, // 历史记录弹窗
    }
  },
  computed: {
    ...mapState({
      history_car: state => state.car.history_car,
    }),
    car_number() {
      let area_name = this.areaName ? this.areaName : ' '
      let area_letter = this.areaLetter ? this.areaLetter : ' '
      let num_one = this.numOne ? this.numOne : ' '
      let num_two = this.numTwo ? this.numTwo : ' '
      let num_three = this.numThree ? this.numThree : ' '
      let num_four = this.numFour ? this.numFour : ' '
      let num_five = this.numFive ? this.numFive : ' '
      let num_six = this.numSix ? this.numSix : ' '

      let res = area_name + area_letter + num_one + num_two + num_three + num_four + num_five + num_six
      return res
    },
  },
  watch: {
    checkbox(val) {
      this.isNumOne = false
      this.isNumTwo = false
      this.isNumThree = false
      this.isNumFour = false
      this.isNumFive = false
      this.isNumSix = false
      if (!val) {
        // 切换到普通车牌
        if (this.numFour) {
          this.isNumFive = true
          this.key = 7
        }
      } else {
        // 切换到新能源车牌
        if (this.numFive) {
          this.isNumSix = true
          this.key = 8
        }
      }
    },
    keyboardShow(val) {
      this.$emit('keyboardStatu', val)
    },
    carNumAll(val) {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      // 处理接收的车牌
      let carIdArray = this.carNumAll === '' ? (process.env.VUE_APP_CARFIRST ? process.env.VUE_APP_CARFIRST.split('') : []) : this.carNumAll.split('') // 如果是空的，则补充“鄂A”

      this.areaName = carIdArray[0]
      this.areaLetter = carIdArray[1]
      this.numOne = carIdArray[2]
      this.numTwo = carIdArray[3]
      this.numThree = carIdArray[4]
      this.numFour = carIdArray[5]
      this.numFive = carIdArray[6]
      if (carIdArray.length > 7) {
        this.numSix = carIdArray[7]
        this.checkbox = true
      }
      this.checkbox = this.firstCheckbox

      this.sentCarIdGoParent()
    },
    carTypeChange(val) {
      this.isNumOne = false
      this.isNumTwo = false
      this.isNumThree = false
      this.isNumFour = false
      this.isNumFive = false
      this.isNumSix = false
      if (!val) {
        // 切换到普通车牌
        if (this.numFour) {
          this.isNumFive = true
          this.key = 7
        }
      } else {
        // 切换到新能源车牌
        if (this.numFive) {
          this.isNumSix = true
          this.key = 8
        }
      }
    },
    btnClickYue() {
      this.isYue = true
      this.isA = false
      this.isUp = true
      this.isNumOne = false
      this.isNumTwo = false
      this.isNumThree = false
      this.isNumFour = false
      this.isNumFive = false
      this.isNumSix = false
      this.istextDisable = false
      this.keyboardShow = true
      this.keyboard = 'txt'
      this.key = 1
    },
    btnWordClick(rows, index, i) {
      this.selected = i
      if (this.key === 1) {
        if (this.areaName === '临') {
          this.areaLetter = ''
          this.numOne = ''
          this.numTwo = ''
          this.numThree = ''
          this.numFour = ''
          this.numFive = ''
          this.numSix = ''
        }
        this.areaName = i
        this.isSelectl = false
        document.getElementById('letter').click()
      } else if (this.key === 2) {
        this.areaLetter = i
        document.getElementById('numOne').click()
      } else if (this.key === 3) {
        this.numOne = i
        document.getElementById('numTwo').click()
      } else if (this.key === 4) {
        this.numTwo = i
        document.getElementById('numThree').click()
      } else if (this.key === 5) {
        this.numThree = i
        document.getElementById('numFour').click()
      } else if (this.key === 6) {
        this.numFour = i
        document.getElementById('numFive').click()
      } else if (this.key === 7) {
        this.numFive = i
        if (this.checkbox) {
          document.getElementById('numSix').click()
        }
      } else if (this.key === 8) {
        this.numSix = i
        this.completeClick()
      }
      this.carNumBottom = ['W', 'X', 'Y', 'Z', '港', '澳', '学']
      this.noneBottom = ['']
      if (this.key === 7 || this.key === 8) {
        // this.carNumBottom = ['W', 'X', 'Y', 'Z', '港', '澳', '学']
        this.istextDisable = true
        // this.noneBottom = ['']
      } else if (this.key === 3 || this.key === 4 || this.key === 5 || this.key === 6) {
        // this.carNumBottom = ['W', 'X', 'Y', 'Z']
        this.istextDisable = false
        // this.noneBottom = ['', '', '', '']
      }
      this.sentCarIdGoParent()
    },
    btnBottomClick(val) {
      if (val === '新') {
        if (this.areaName === '临') {
          this.areaLetter = ''
          this.numOne = ''
          this.numTwo = ''
          this.numThree = ''
          this.numFour = ''
          this.numFive = ''
          this.numSix = ''
        }
        this.areaName = '新'
        this.isSelectx = true
        this.isSelectl = false
        document.getElementById('letter').click()
      } else if (val === '临') {
        this.areaName = '临'
        this.isSelectl = true
        this.isSelectx = false
        this.isDisable = true
        this.areaLetter = ''
        this.numOne = ''
        this.numTwo = ''
        this.numThree = ''
        this.numFour = ''
        this.numFive = ''
        this.numSix = ''
        document.getElementById('letter').click('isLin')
      }
      this.sentCarIdGoParent()
    },
    btnBottomNumClick(i) {
      this.selected = i
      if (this.key === 2) {
        this.areaLetter = i
        document.getElementById('numOne').click()
      } else if (this.key === 3) {
        this.numOne = i
        document.getElementById('numTwo').click()
      } else if (this.key === 4) {
        this.numTwo = i
        document.getElementById('numThree').click()
      } else if (this.key === 5) {
        this.numThree = i
        document.getElementById('numFour').click()
      } else if (this.key === 6) {
        this.numFour = i
        document.getElementById('numFive').click()
      } else if (this.key === 7) {
        this.numFive = i
        if (this.checkbox) {
          document.getElementById('numSix').click()
        }
      } else if (this.key === 8) {
        this.numSix = i
      }
      this.sentCarIdGoParent()
    },
    btnClickA() {
      this.isDisable = true
      this.isA = true
      this.isYue = false
      this.isUp = true
      this.isNumOne = false
      this.isNumTwo = false
      this.isNumThree = false
      this.isNumFour = false
      this.isNumFive = false
      this.isNumSix = false
      this.istextDisable = false
      this.keyboardShow = true
      this.keyboard = 'num'
      this.key = 2
    },
    btnClickNum(name) {
      if (this.isSelectl) {
        this.isDisable = true
      } else {
        this.isDisable = false
      }
      this.keyboard = 'num'
      this.keyboardShow = true
      this.isYue = false
      this.isA = false
      this.isNumOne = false
      this.isNumTwo = false
      this.isNumThree = false
      this.isNumFour = false
      this.isNumFive = false
      this.isNumSix = false
      this.isUp = true
      if (name === 'one') {
        this.isNumOne = true
        this.key = 3
      } else if (name === 'two') {
        this.isNumTwo = true
        this.key = 4
      } else if (name === 'three') {
        this.isNumThree = true
        this.key = 5
      } else if (name === 'four') {
        this.isNumFour = true
        this.key = 6
      } else if (name === 'five') {
        this.isNumFive = true
        this.key = 7
      } else if (name === 'six') {
        this.isNumSix = true
        this.key = 8
      }
      return
      this.carNumBottom = ['W', 'X', 'Y', 'Z', '港', '澳', '学']
      this.noneBottom = ['']
      if (name === 'five' || name === 'six') {
        // this.carNumBottom = ['W', 'X', 'Y', 'Z', '港', '澳', '学']
        this.istextDisable = true
        // this.noneBottom = ['']
      } else {
        // this.carNumBottom = ['W', 'X', 'Y', 'Z']
        this.istextDisable = false
        // this.noneBottom = ['', '', '', '']
      }
    },
    // 点击完成
    completeClick() {
      this.isYue = false
      this.isA = false
      this.isNumOne = false
      this.isNumTwo = false
      this.isNumThree = false
      this.isNumFour = false
      this.isNumFive = false
      this.isNumSix = false
      this.isUp = false
      this.isDown = true
      setTimeout(() => {
        this.keyboardShow = false
      }, 200)

      let area_name = this.areaName ? this.areaName : ' '
      let area_letter = this.areaLetter ? this.areaLetter : ' '
      let num_one = this.numOne ? this.numOne : ' '
      let num_two = this.numTwo ? this.numTwo : ' '
      let num_three = this.numThree ? this.numThree : ' '
      let num_four = this.numFour ? this.numFour : ' '
      let num_five = this.numFive ? this.numFive : ' '
      let num_six = this.numSix ? this.numSix : ' '

      let sentCarId = area_name + area_letter + num_one + num_two + num_three + num_four + num_five + num_six
      this.$emit('update:carNumAll', sentCarId)
    },
    // 删除填写
    clearClick() {
      if (this.key === 1) {
        this.areaName = ''
      } else if (this.key === 2) {
        document.getElementById('font').click()
        this.areaLetter = ''
      } else if (this.key === 3) {
        document.getElementById('letter').click()
        this.numOne = ''
      } else if (this.key === 4) {
        document.getElementById('numOne').click()
        this.numTwo = ''
      } else if (this.key === 5) {
        document.getElementById('numTwo').click()
        this.numThree = ''
      } else if (this.key === 6) {
        document.getElementById('numThree') && document.getElementById('numThree').click()
        this.numFour = ''
      } else if (this.key === 7) {
        document.getElementById('numFour').click()
        this.numFive = ''
      } else if (this.key === 8) {
        document.getElementById('numFive').click()
        this.numSix = ''
      }
      this.sentCarIdGoParent()
    },
    addCar() {
      var num
      if (!this.checkbox) {
        num = this.areaName + this.areaLetter + this.numOne + this.numTwo + this.numThree + this.numFour + this.numFive
      } else if (this.checkbox) {
        num = this.areaName + this.areaLetter + this.numOne + this.numTwo + this.numThree + this.numFour + this.numFive + this.numSix
      }
      if (num === '') {
        this.$dialog.toast({mes: '请输入车牌', timeout: 1000})
      } else if (
        !this.checkbox &&
        (this.areaName === '' ||
          this.areaLetter === '' ||
          this.numOne === '' ||
          this.numTwo === '' ||
          this.numThree === '' ||
          this.numFour === '' ||
          this.numFive === '')
      ) {
        this.$dialog.toast({mes: '请输入完整车牌', timeout: 1000})
      } else if (
        this.checkbox &&
        (this.areaName === '' ||
          this.areaLetter === '' ||
          this.numOne === '' ||
          this.numTwo === '' ||
          this.numThree === '' ||
          this.numFour === '' ||
          this.numFive === '' ||
          this.numSix === '')
      ) {
        this.$dialog.toast({mes: '请输入完整车牌', timeout: 1000})
      }
    },
    // 同步发送给父级
    sentCarIdGoParent() {
      this.$emit('update:carNumAll', this.car_number)
    },
    // 点击历史记录
    chooseHistoryCar(item) {
      let {name, number} = item
      number = number.split('')
      this.isYue = false
      this.isA = false
      this.isNumOne = false
      this.isNumTwo = false
      this.isNumThree = false
      this.isNumFour = false
      this.isNumFive = false
      this.isNumSix = false
      this.isUp = false
      this.isDown = true
      setTimeout(() => {
        this.keyboardShow = false
      }, 200)

      let area_name = number[0] ? number[0] : ' '
      let area_letter = number[1] ? number[1] : ' '
      let num_one = number[2] ? number[2] : ' '
      let num_two = number[3] ? number[3] : ' '
      let num_three = number[4] ? number[4] : ' '
      let num_four = number[5] ? number[5] : ' '
      let num_five = number[6] ? number[6] : ' '
      let num_six = number[7] ? number[7] : ' '

      let sentCarId = area_name + area_letter + num_one + num_two + num_three + num_four + num_five + num_six
      // 把车牌发送同步于父级
      this.$emit('update:carNumAll', sentCarId)
      // 传递参数给父级，用于其他数据处理
      this.$emit('sendCarData', item)

      this.historycar_show = false
    },
    // 点击删除历史记录
    deleteHistoryCar(item) {
      Dialog.confirm({
        title: '标题',
        message: '确定删除',
      })
        .then(() => {
          this.$store.dispatch('car/removeCarHistory', item)
        })
        .catch(() => {
          // on cancel
        })
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.areaName = ''
      vm.areaLetter = ''
      vm.numOne = ''
      vm.numTwo = ''
      vm.numThree = ''
      vm.numFour = ''
      vm.numFive = ''
      vm.numSix = ''
    })
  },
}
</script>

<style rel="stylesheet/scss" lang="scss">
.checkborder {
  width: 0.25rem;
  height: 0.25rem;
  color: rgb(76, 216, 100);
  i {
    width: 12px;
    height: 12px;
  }
}
#dispense {
  width: 100%;
  display: block;
  overflow: hidden;
  .navbar {
    .right {
      a {
        font-size: 30px;
        color: #ffc200;
      }
    }
  }
  .ze-checkbox > input[type='checkbox'] {
    position: absolute;
    left: -9999em;
  }

  .ze-checkbox > input[type='checkbox']:checked + .ze-checkbox-icon {
    border-color: #1989fa;
    color: #1989fa;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ze-checkbox-icon {
    border: 0.01333rem solid #ccc;
    border-radius: 0.02667rem;
    display: inline-block;
    position: relative;
    z-index: 10;
    vertical-align: bottom;
    pointer-events: none;
  }

  .ze-checkbox > input[type='checkbox']:checked + .ze-checkbox-icon > i {
    width: 0.18rem;
    height: 0.18rem;
    background: #1989fa;
    display: block;
    overflow: hidden;
  }
  background-color: #fff;
  .content-car-number {
    padding: 0.3rem 0 0;
    .car-content-title {
      display: flex;
      justify-content: space-between;
      // padding: 0 32px;
      margin-bottom: 5px;
      span {
        font-size: 0.25rem;
      }
      .ze-checkbox {
        display: flex;
        align-items: center;
      }
      .ze-radio-text {
        font-size: 28px;
      }
      .ze-checkbox-icon {
        color: #ffedb1 !important;
        margin-right: 8px;
      }
    }
    .content-section {
      .content-section-flex {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.4rem;
        width: 100%;
        border: 1px solid #ccc;
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        button {
          width: auto;
          height: 1rem;
          display: block;
          overflow: hidden;
          flex: 1;
          border: 0;
          background: none;
          border: 1px solid #ccc;
          box-sizing: border-box;
          padding: 0;
          margin: 0;
          &:nth-last-child(1) {
            background: #f2fbf2;
            border-color: #7fd77b;
          }
          &.isClick {
            background: #fff5d4;
            border-color: #ffc200;
          }
          &.isNumClick {
            background: #fff5d4;
            border-color: #ffc200;
          }
        }
        .blank-border {
          height: 44px;
          width: 1px; /*no*/
          border-right: 1px solid #ddd; /*no*/
          margin-top: 27px;
          float: left;
          margin-left: -15px;
          z-index: 1;
          &.blank-border-first {
            margin-top: 0px;
          }
        }
        &.flex-card {
          color: #000;
          .flex-btn {
            width: 98px;
            height: 98px;
            text-align: center;
            font-size: 40px;
            color: #000;
          }
          .flex-btns {
            text-align: center;
            background: #fff;
            width: fit-content;
            position: relative;
            height: 98px;
            display: flex;
            button {
              width: 88px;
              height: 98px;
              border: none;
              font-size: 40px;
              color: #000;
              border-radius: 50%;
              background: transparent;
              float: left;
              margin-left: 0;
              z-index: 2;
              position: relative;
              &:first-child {
                margin-left: 0;
              }
            }
          }
          .flex-mid {
            margin: 0 0.1rem;
            span {
              display: block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: #ddd;
            }
          }
        }
        .flex-card-border {
          border: 1px solid #ddd; /*no*/
          border-radius: 8px;
          box-sizing: border-box;
        }
      }
    }
  }
  .fee-hours {
    padding: 0 32px;
    margin-bottom: 80px;
    .fee-hours-title {
      margin-bottom: 16px;
      font-size: 34px;
    }
    .fee-hours-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      &.fee-hours-num {
        display: block;
      }
    }
    .fee-hours-num {
      width: 152px;
      height: 64px;
      font-size: 28px;
      background: #ececec;
      border-radius: 8px;
      text-align: center;
      line-height: 64px;
    }
    .isFeeHour {
      background: #ffeeb1;
    }
  }
  .searchPay-btn {
    text-align: center;
    /*background-color: #f6f6f6;*/
    margin: 0 32px 0;
    button {
      width: 686px;
      height: 88px;
      color: #ffffff;
      font-size: 32px;
      background: #ececec;
      border: none;
      border-radius: 8px;
      &.searchPay-btn-click {
        box-shadow: 0px 5px 10px 0px rgba(255, 227, 135, 1);
        background: #ffc200;
        color: #101010;
      }
    }
  }
  .keyboard {
    width: 100%;
    height: 5.5rem;
    position: fixed;
    bottom: -5.5rem;
    left: 0;
    background: #eeeeee;
    z-index: 10;
    &.animationDown {
      animation: slide_dowms 0.3s ease-out;
      animation-fill-mode: forwards;
    }
    &.animationUp {
      animation: slide_ups 0.3s ease-out;
      animation-fill-mode: forwards;
    }
    .complete-box {
      width: 100%;
      height: 0.7rem;
      display: flex;
      overflow: hidden;
      align-items: center;
      background: #fff;
      border-top: 1px solid #eee;
      margin-bottom: 10px;
      .complete-usecar {
        width: auto;
        display: block;
        overflow: hidden;
        flex: 1;
        margin: 0 0 0 10px;
        li {
          width: auto;
          height: auto;
          padding: 0.13333rem 0.21333rem;
          float: left;
          margin: 0 0 0 0.26667rem;
          display: inline;
          overflow: hidden;
          background: #f5f5f5;
          border-radius: 1.33333rem;
          font-size: 0.21rem;
          // width: auto; height: auto; padding: 10px 16px; float: left; margin: 0 0 0 20px; display: inline; overflow: hidden; background: #f5f5f5; border-radius: 100px;
        }
      }
      .btn-complete {
        background: none;
        padding: 0 30px;
        color: #ffc200;
        font-size: 0.3rem;
        border: none;
      }
    }
    .keyboard-row {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      padding: 0 8px;
      color: #333;
      &:first-child {
        margin-top: 0;
      }
      .keyboard-row-items {
        display: flex;
        justify-content: left;
      }
      .keyboard-row-bottom {
        display: flex;
        justify-content: space-between;
      }
      .keyboard-row-item {
        width: 0.6rem;
        height: 0.8rem;
        background: #fff;
        font-size: 0.3rem;
        text-align: center;
        border: 1px solid #ccc; /*no*/
        border-radius: 5px;
        color: #333;
        padding: 0;
        &.bottom {
          width: 0.6rem;
          height: 0.8rem;
          line-height: 0.8rem;
          background: #fff;
          color: #333;
          /*margin-right: 0.08rem;*/
        }
        &.clear {
          width: 1.3rem;
          height: 0.8rem;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 0.6rem;
          }
        }
      }
      .none-botton {
        border: none;
        width: 0.6rem;
        height: 0.8rem;
        visibility: hidden;
      }
    }
    button {
      &:active {
        background: #f4f4f4 !important;
        color: #999 !important;
      }
    }
  }
  @keyframes slide_ups {
    from {
      bottom: -502px;
    }
    to {
      bottom: 0px;
    }
  }
  @keyframes slide_dowms {
    from {
      bottom: 0px;
    }
    to {
      bottom: -502px;
    }
  }
  button:disabled {
    background: #f4f4f4 !important;
    color: #8f8f8f !important;
  }
  .active {
    &:active {
      background: #f4f4f4 !important;
      color: #999 !important;
    }
  }
}
</style>
